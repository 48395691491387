export function isAuth() {
    const data = JSON.parse(sessionStorage.getItem('user'));

    if( data.user ){
        return data.user;
    }else{
        return false;
    }
}

export default function getToken() {
    const data = sessionStorage.getItem('user');

    if( data !== null ){
        return JSON.parse(data);
    }else{
        return null;
    }
}

export function genSecureToken() {
    let pwst = new Uint32Array(10);
    window.crypto.getRandomValues(pwst);
    return pwst[Math.floor(Math.random() * 10)];
}