import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { AimOutlined } from '@ant-design/icons';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

import { connect } from 'react-redux';
import * as registerActions from '../actions/registerActions';

export class MapFrm extends Component {
    constructor(props){
        super(props);

        this.state = {
            latitude: 2.44189989,
            longitude: -76.60630077,
            btnFinalizar_active: false
        }
        this.getLocation = this.getLocation.bind(this);
        this.getCoordinates = this.getCoordinates.bind(this);
    }

    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoordinates);
        } else {
            alert("La geolocalización no está soportada en este navegador");
        }
    }

    getCoordinates = (position) => {
        /* console.log("latitude: " + position.coords.latitude);
        console.log("longitude: " + position.coords.longitude); */
        
        this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    }

    //se carga los estilos personalizados del mapa
    _mapLoaded(mapProps, map) {
        map.setOptions({
           styles: mapStyle
        })
    }
    
    //obtiene lat, long del maker que mueve el usuario
    moveMarker = (coord) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.setState({btnFinalizar_active:true, latitude:lat,longitude:lng});
        this.props.clickHandler(lat, lng);
    }

    //
    btnFinalizar = async () => {
        let { latitude,longitude } = this.state;
        if(latitude !== 2.44189989 && longitude !== -76.60630077){
            //await this.props.clickHandler(latitude,longitude);
            await this.props.closeModal();
            await this.props.saveRegister();
        } else {
            alert("Por favor mueva el marcador rojo y ubíquelo donde está su negocio.");
        }
    }

    render(){
        let { latitude,longitude } = this.state;
        
        return(
            <div style={{height:400, backgroundColor:'#fff'}}>
                <div style={{padding:9,lineHeight:1, textAlign:'center'}}>
                    <span style={{fontWeight:'bold'}}>Por favor arrastrar el marcador rojo hasta el sitio donde se encuentra su negocio, luego puede dar clic en finalizar solicitud.</span>
                </div>
                <Map
                    className="map"
                    google={this.props.google} zoom={17}
                    fullscreenControl = {false}
                    mapTypeControl = {false}
                    streetViewControl = {false}
                    geoloccontrol = {true}
                    initialCenter={{
                        lat: 2.44189989,
                        lng: -76.60630077
                    }}
                    center={{
                        lat: latitude,
                        lng: longitude
                    }}
                    onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                    //onClick={this.onMarkerClick}
                >
                    <Marker
                        title="Location"
                        id={1}
                        position={{
                            lat: latitude,
                            lng: longitude
                        }}
                        draggable={true}
                        //onDragend={this.moveMarker.bind(this)}
                        onDragend={(t, map, coord) => this.moveMarker(coord)}
                    >
                    </Marker>
                    <Tooltip placement="left" title="Ubicar mi localización">
                        <div className="btnLocation" onClick={this.getLocation} alt="Ubicar mi localización">
                            <AimOutlined style={{fontSize:30, padding:5}} />
                        </div>
                    </Tooltip>
                    <div style={{width:'100%', bottom:0, position:'absolute', textAlign:'center', marginBottom:10}}>
                        {/* <Button type="primary" htmlType="submit" onClick={this.btnSiguiente} disabled={!this.state.terminos}>
                            Finalizar
                        </Button> */}
                        <Button type="primary" htmlType="submit" onClick={this.btnFinalizar} style={{border:'none'}} disabled={!this.state.btnFinalizar_active}>
                            Finalizar solicitud
                        </Button>
                    </div>
                </Map>
            </div>
        );
    }
}

MapFrm.propTypes = {
    clickHandler: PropTypes.func.isRequired
}

const mapStateToProps = (reducers) => {
    return reducers.registerReducer;
}

export default connect(mapStateToProps, registerActions)(GoogleApiWrapper({
    apiKey: ('AIzaSyAC6XFI-ubdlsgk_OEl-GviZJCYJehN0HA') // Producción
    //apiKey: ('AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo') // Desarrollo
})(MapFrm))

/* Estilo y tipo de mapa */
const mapStyle = [
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#dceafa'
            }
        ]
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off"
            }
        ]
    }
]