import React from 'react';
import BackendLayout from '../../Components/Base/BackendLayout/BackendLayout';
import AgergarBanners from '../../Components/AgergarBanners';

export default function AgregarBanners() {
    return (
        <BackendLayout>
            <AgergarBanners />
        </BackendLayout>
    )
}