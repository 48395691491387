import React from 'react';
import BackendLayout from '../../Components/Base/BackendLayout/BackendLayout';
import Presentaciones from '../../Components/Presentaciones';

export default function Productos() {
    return (
        <BackendLayout>
            <Presentaciones />
        </BackendLayout>
    )
}