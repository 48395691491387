export const getStatusModal = () => (dispatch) => {
    dispatch({
        type: 'statusModal',
        payload: true
    })
}

export const closeModal = () => (dispatch) => {
    dispatch({
        type: 'statusModal',
        payload: false
    })
}