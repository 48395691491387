import axios from 'axios';


export const _SERVER_ = "https://ilcauca.stac.com.co";
//export const _SERVER_ = 'https://d907815eaad2.ngrok.io';

function getToken() {
    const data = sessionStorage.getItem('user');

    if( data !== null ){
        //console.log("data user== ",data);
        return JSON.parse(data);
    }else{
        return null;
    }
}

//obtiene las imágenes para el banner
export function getBanners(tipo='') {
    const ruta = _SERVER_ + '/banners';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta )
        .then(result => result.json())
        .then(result => {
            //console.log("getData BANNER== ",result);
            if( result ){
                if(tipo === 'activos' || tipo=== ''){
                    //console.log("getData==",result);
                    const filterResult = result.filter(item => item.activo === true);
                    resolve( filterResult );
                }else if(tipo === 'todos'){
                    resolve( result );
                }
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error get banners ",error);
            alert(error);
        });
    });

    return promise;
}

//obtiene info para crear los marcadores para el mapa y lista de negocios
export function getAllShops(tipo='') {
    const ruta = _SERVER_ + '/negocios?_limit=-1';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta )
        .then(result => result.json())
        .then(result => {
            //console.log("getData== ",result.result);
            if( result ){
                if(tipo === 'activos' || tipo=== ''){
                    //console.log("getData==",result);
                    const filterResult = result.filter(item => item.activo === true);
                    resolve( filterResult );
                }else if(tipo === 'todos'){
                    resolve( result );
                }
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error get all shops ",error);
            alert(error);
        });
    });

    return promise;
}

//obtiene info de un negocio específico
export function getShop(email) {
    const ruta = _SERVER_ + '/negocios?_where[correo]=' + email;

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta )
        .then(result => result.json())
        .then(result => {
            //console.log("getData== ",result.result);
            if( result ){
                resolve( result );
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error get all shops ",error);
            alert(error);
        });
    });
    return promise;
}

//guarda nueva solicitud de registro
export const saveRegister = async (negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,latitude,longitude) => {
    const ruta = _SERVER_ + '/negocios';
    //console.log("saveToCartAPI=cart== ", cart);
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nombre: negocio,
                propietario: propietario,
                identificacion: idpropietario,
                direccion: direccion,
                telefono: telefono,
                celular: celular,
                whatsapp: whatsapp,
                correo: correo,
                latitud: latitude,
                longitud: longitude,
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                console.log("Error API== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save register ",error);
            alert(error.message);
        });
    });

    return promise;
}

//actualiza tabla 'negocios'
export const updateRegister = async (idReg,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,producto) => {
    const ruta = _SERVER_ + '/negocios/' + idReg;

    let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${getToken().jwt}`);

    let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
                nombre: negocio,
                propietario: propietario,
                identificacion: idpropietario,
                direccion: direccion,
                telefono: telefono,
                celular: celular,
                whatsapp: whatsapp,
                correo: correo,
                productos: producto,
            }),
        };

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, requestOptions)
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save register ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

//actualiza estado activo de tabla 'negocios'
export const updateRegisterStatus = async (idReg, activo) => {
    const ruta = _SERVER_ + '/negocios/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                activo: activo
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save register ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

//actualiza fotografía del negocio
export const updatePhoto = async (idReg, foto) => {
    const ruta = _SERVER_ + '/upload';

    let promise = new Promise( async (resolve, reject) => {

        const formData = new FormData()
        Array.from(foto).forEach(image => {
            formData.append('files', image);
        });
        formData.append('ref', 'negocios')
        formData.append('refId', idReg)
        formData.append('field', 'fotografia_negocio')

        axios.post(ruta, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(result => {
            //console.log("API== ",result);
            if(result.statusText === 'OK'){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save photo ",error);
            reject(error);
            //alert(error);
        });

        /* const data = new FormData();
        data.append('files', {
          uri: JSON.stringify(uri),
          name: `test.jpg`,
          type: 'multipart/form-data'
        });

        await fetch( ruta, {
            method: 'PUT',
            body: data
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save register ",error);
            reject(error);
            //alert(error);
        }); */
    });

    return promise;
}

//Elimina item de tabla 'negocios'
export const deleteRegister = async (idReg) => {
    const ruta = _SERVER_ + '/negocios/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error deleting negocio ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}


/*
 *   PRESENTACION
 */

//obtiene listado de presentaciones
export function getAllPresentaciones() {
    const ruta = _SERVER_ + '/presentaciones';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta )
        .then(result => result.json())
        .then(result => {
            //console.log("getData== ",result.result);
            if( result ){
                //resolve( result );
                let res = result.map( function (item) {
                    return ({key: item.id, nombre: item.nombre});
                });

                resolve(res);
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error al obtener presentaciones ",error);
            alert(error);
        });
    });

    return promise;
}

//guarda nueva presentación
export function savePresentacion(nombre) {
    const ruta = _SERVER_ + '/presentaciones';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                nombre: nombre,
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                console.log("Error API Presentacion== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save presentacion ",error);
            alert(error.message);
        });
    });

    return promise;
}

//actualiza item de tabla 'presentacion'
export const updatePresentacion = async (idReg,nombre) => {
    const ruta = _SERVER_ + '/presentaciones/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                nombre: nombre,
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error updating presentacion ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

//Elimina item de tabla 'presentacion'
export const deletePresentacion = async (idReg) => {
    const ruta = _SERVER_ + '/presentaciones/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error deleting presentacion ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}


/*
 *  PRODUCTOS
 */

//obtiene info para crear los marcadores para el mapa
export function getAllProductos() {
    const ruta = _SERVER_ + '/productos';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta )
        .then(result => result.json())
        .then(result => {
            //console.log("getData== ",result.result);
            if( result ){
                resolve( result );
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error get all productos ",error);
            alert(error);
        });
    });

    return promise;
}

//guarda nuevo 'productos'
export const saveProducto = async (producto, descripcion, presentacion) => {
    const ruta = _SERVER_ + '/productos';
    //console.log("saveToCartAPI=cart== ", cart);
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                producto: producto,
                descripcion: descripcion,
                presentaciones: presentacion
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                console.log("Error API save productos== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save productos ",error);
            alert(error.message);
        });
    });

    return promise;
}

//actualiza item de tabla 'productos'
export const updateProducto = async (idReg, producto, descripcion, presentacion) => {
    const ruta = _SERVER_ + '/productos/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                producto: producto,
                descripcion: descripcion,
                presentaciones: presentacion
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            //console.log("error updating productos ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}
//actualiza fotografía del producto
export const updatePhotoProducto = async (idReg, foto) => {
    const ruta = _SERVER_ + '/upload';

    let promise = new Promise( async (resolve, reject) => {

        const formData = new FormData()
        Array.from(foto).forEach(image => {
            formData.append('files', image);
        });
        formData.append('ref', 'productos')
        formData.append('refId', idReg)
        formData.append('field', 'imagen')

        axios.post(ruta, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(result => {
            //console.log("API== ",result);
            if(result.statusText === 'OK'){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            //console.log("error save photo productos ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

//Elimina item de tabla 'presentacion'
export const deleteProducto = async (idReg) => {
    const ruta = _SERVER_ + '/productos/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error deleting producto ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

/*
 *   USUARIOS
 */

 //verifica si el nombre de usuario existe, devuelve todos los datos
 export function verifyUser(indentificacion) {
    const ruta = _SERVER_ + '/users?_where[username]=' + indentificacion;

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
        })
        .then(result => result.json())
        .then(result => {
            if( result ){
                if(Array.isArray(result)){
                    if( result.length > 0 ){
                        resolve( result );
                    }else{
                        resolve( false );
                    }
                }else{
                    reject(result);
                    alert(result);
                }
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error get user ",error);
            alert(error);
        });
    });
    return promise;
}

//crea usuarios del tipo cliente, para acceso a perfil del negocio
export const createBusinessUser = async (username,email,password,blocked) => {
    const ruta = _SERVER_ + '/users';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                username: username,
                email: email,
                password: password,
                "role": 5,
                blocked: blocked
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API creando usuario== ",result);
                reject(result);
            }
        })
        .catch(error => {
            //console.log("error creando usuario ",error);
            alert(error.message);
        });
    });

    return promise;
}

//bloquea o desbloquea cuenta de usuario del negocio
export const updateStatusBusinessUser = async (id,newstatus) => {
    const ruta = _SERVER_ + '/users/' + id;

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                blocked: newstatus
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                //console.log("pws== ", password);
                resolve(result);
            }else{
                //console.log("Error API creando usuario== ",result);
                reject(result);
            }
        })
        .catch(error => {
            //console.log("error creando usuario ",error);
            alert(error.message);
        });
    });

    return promise;
}

//actualiza password de la cuenta de usuario del tipo 'negocio'
export const updatePasswordBusinessUser = async (id,newpass) => {
    const ruta = _SERVER_ + '/users/' + id;

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                password: newpass
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                //console.log("pws== ", password);
                resolve(result);
            }else{
                //console.log("Error API creando usuario== ",result);
                reject(result);
            }
        })
        .catch(error => {
            //console.log("error creando usuario ",error);
            alert(error.message);
        });
    });

    return promise;
}

//logueo de usuarios
export function loginUsr(usr,pws) {
    const ruta = _SERVER_ + '/auth/local';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                identifier: usr,
                password: pws
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("logueo== ",result.user);
            if( result.user ){
                resolve(result);
            }else if( result.statusCode === 400 && result.message[0].messages[0].message === 'Identifier or password invalid.' ){
                reject( "Los datos ingresados no coinciden." );
            }else if( result.statusCode === 400 && result.message[0].messages[0].message === 'Your account has been blocked by an administrator' ){
                reject( "Su cuenta se encuentra deshabilitada." );
            }else{
                reject( result );
            }
        })
        .catch(error =>{
            //console.log("Error al loguearse ",error);
            if(error.message[0].messages[0].message === 'Identifier or password invalid.'){
                reject( "Los datos ingresados no coinciden." );
            }
        });
    });

    return promise;
}


/*
 *  BANNERS
 */

//obtiene info para crear los marcadores para el mapa
export function getAllBanners() {
    const ruta = _SERVER_ + '/banners';

    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta )
        .then(result => result.json())
        .then(result => {
            //console.log("getData== ",result.result);
            if( result ){
                resolve( result );
            }else{
                reject( result );
                alert(result);
            }
        })
        .catch(error =>{
            console.log("Error get all banners ",error);
            alert(error);
        });
    });

    return promise;
}

//guarda nuevo 'banner'
export const saveBanner = async (descripcion) => {
    const ruta = _SERVER_ + '/banners';
    //console.log("saveToCartAPI=cart== ", cart);
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                descripcion: descripcion,
                activo: true,
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                console.log("Error API save banners== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save banners ",error);
            alert(error.message);
        });
    });

    return promise;
}

//actualiza fotografía de banner
export const updatePhotoBanner = async (idReg, foto) => {
    const ruta = _SERVER_ + '/upload';

    let promise = new Promise( async (resolve, reject) => {

        const formData = new FormData()
        Array.from(foto).forEach(image => {
            formData.append('files', image);
        });
        formData.append('ref', 'banner')
        formData.append('refId', idReg)
        formData.append('field', 'imagen')

        axios.post(ruta, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(result => {
            //console.log("API== ",result);
            if(result.statusText === 'OK'){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error save photo banners ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

//actualiza item de 'banners'
export const updateBanner = async (idReg, descripcion) => {
    const ruta = _SERVER_ + '/banners/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
            body: JSON.stringify({
                descripcion: descripcion,
            }),
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            //console.log("error updating banners ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}

//Elimina item de 'banners'
export const deleteBanner = async (idReg) => {
    const ruta = _SERVER_ + '/banners/' + idReg;
    let promise = new Promise( async (resolve, reject) => {
        await fetch( ruta, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken().jwt}`
            },
        })
        .then(result => result.json())
        .then(result => {
            //console.log("API== ",result);
            if(result.id){
                resolve(result);
            }else{
                //console.log("Error API saving== ",result);
                reject(result);
            }
        })
        .catch(error => {
            console.log("error deleting banner ",error);
            reject(error);
            //alert(error);
        });
    });

    return promise;
}