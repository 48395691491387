import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { ShopOutlined,UserOutlined,IdcardOutlined,EnvironmentOutlined,PhoneOutlined,ShakeOutlined,WhatsAppOutlined,MailOutlined,CheckCircleOutlined } from '@ant-design/icons';
import MapFrm from './MapFrm';
import './css.css';
import { AppContext } from '../../context/appContext';
import { connect } from 'react-redux';
import * as registerActions from '../actions/registerActions';
import { saveRegister } from '../../Utils/Api';

/* const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
wrapperCol: { offset: 8, span: 16 },
};

const onFinish = values => {
    console.log('Success:', values);
};

const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
}; */

class RegistroNegocios extends Component {
    constructor(props){
        super(props);

        this.state = {
            frmDatosVisible: true,
            frmMapVisible:false,
            negocio:'',
            propietario:'',
            idpropietario:'',
            direccion:'',
            telefono:'',
            celular:'',
            whatsapp:'',
            correo:'',
            terminos:false,
            latitude:null,
            longitude:null,
            successful:false,
            hlpTelefono:'',
            hlpCelular:'',
            hlpWhatsapp:'',
        }
    }

    onChangeNegocio = (event) => {
        this.setState({
            negocio:event.target.value,
        });
    }

    onChangePropietario = (event) => {
        this.setState({
            propietario:event.target.value,
        });
    }

    onChangeIdPropietario = (event) => {
        this.setState({
            idpropietario:event.target.value,
        });
    }

    onChangeDireccion= (event) => {
        this.setState({
            direccion:event.target.value,
        });
    }

    onChangeTelefono = (event) => {
        /* this.setState({
            telefono:event.target.value,
        }); */
        const re = /^[0-9\b]+$/;
        let data = event.target.value;

        if(re.test(data)){
            this.setState({
                hlpTelefono: "",
                telefono:event.target.value,
            });
        }else{
            this.setState({ hlpTelefono: "Por favor ingrese solo números" });
        }
    }

    onChangeCelular = (event) => {
        /* this.setState({
            celular:event.target.value,
        }); */
        const re = /^[0-9\b]+$/;
        let data = event.target.value;

        if(re.test(data)){
            this.setState({
                hlpCelular: "",
                celular:event.target.value,
            });
        }else{
            this.setState({ hlpCelular: "Por favor ingrese solo números" });
        }
    }

    onChangeWhatsapp = (event) => {
        /* this.setState({
            whatsapp:event.target.value,
        }); */
        const re = /^[0-9\b]+$/;
        let data = event.target.value;

        if(re.test(data)){
            this.setState({
                hlpWhatsapp: "",
                whatsapp:event.target.value,
            });
        }else{
            this.setState({ hlpWhatsapp: "Por favor ingrese solo números" });
        }
    }

    onChangeCorreo = (event) => {
        this.setState({
            correo:event.target.value,
        });
    }

    onChangeCheck = (event) => {
        this.setState({terminos: event.target.checked});
    }

    frmDatos = () => {
        return(
            <div style={{paddingTop:20,paddingBottom:20,paddingLeft:60,paddingRight:60,textAlign:'center'}}>
                <Form
                    //{...layout}
                    name="registroLicorera"
                    //initialValues={{ remember: true }}
                    //onFinish={onFinish}
                    //onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="negocio"
                        rules={[{ required: true, message: 'El nombre del negocio es necesario.' }]}
                    >
                        <Input placeholder="Nombre del negocio" id="negocio" prefix={<ShopOutlined style={{fontSize:21}} />} onChange={this.onChangeNegocio.bind(this)} value={this.state.negocio} />
                    </Form.Item>
                
                    <Form.Item
                        name="propietario"
                        rules={[{ required: true, message: 'El nombre de propietario/a es necesario.' }]}
                    >
                        <Input placeholder="Nombre de propietario/a del negocio" id="propietario" prefix={<UserOutlined style={{fontSize:18}} />} onChange={this.onChangePropietario.bind(this)} value={this.state.propietario} />
                    </Form.Item>

                    <Form.Item
                        name="idpropietario"
                        rules={[{ required: true, message: 'La identificación de propietario/a es necesaria.' }]}
                    >
                        <Input placeholder="Número de identificación de propietario/a" id="idpropietario" prefix={<IdcardOutlined style={{fontSize:21}} />} onChange={this.onChangeIdPropietario.bind(this)} value={this.state.idpropietario} />
                    </Form.Item>

                    <Form.Item
                        name="direccion"
                        rules={[{ required: true, message: 'La dirección del negocio es necesaria.' }]}
                    >
                        <Input placeholder="Dirección del negocio" id="direccion" prefix={<EnvironmentOutlined style={{fontSize:21}} />} onChange={this.onChangeDireccion.bind(this)} value={this.state.direccion} />
                    </Form.Item>

                    <Form.Item
                        name="telefono"
                    >
                        <Input placeholder="Teléfono fijo del negocio" id="telefono" prefix={<PhoneOutlined style={{fontSize:21}} />} onChange={this.onChangeTelefono.bind(this)} value={this.state.telefono} />
                    </Form.Item>
                        <span className='adver'>{this.state.hlpTelefono}</span>

                    <Form.Item
                        name="celular"
                        rules={[{ required: true, message: 'El número celular del negocio es necesario.' }]}
                    >
                        <Input placeholder="Número celular del negocio" id="celular" prefix={<ShakeOutlined style={{fontSize:21}} />} onChange={this.onChangeCelular.bind(this)} value={this.state.celular} />
                    </Form.Item>
                        <span className='adver'>{this.state.hlpCelular}</span>

                    <Form.Item
                        name="whatsapp"
                    >
                        <Input placeholder="Número de whatsapp del negocio" id="whatsapp" prefix={<WhatsAppOutlined style={{fontSize:21}} />} onChange={this.onChangeWhatsapp.bind(this)} value={this.state.whatsapp} />
                    </Form.Item>
                        <span className='adver'>{this.state.hlpWhatsapp}</span>

                    <Form.Item
                        name="correo"
                        rules={[{ type: 'email', required: true, message: 'Debe ingresar un correo válido.' }]}
                    >
                        <Input placeholder="Correo electrónico para notificaciones" id="correo" prefix={<MailOutlined style={{fontSize:21}} />} onChange={this.onChangeCorreo.bind(this)} value={this.state.correo} />
                    </Form.Item>

                    <Form.Item>
                        <Checkbox checked={this.state.terminos} onClick={this.onChangeCheck.bind(this)}>
                            Acepto la política de privacidad, términos y condiciones <a href="https://ilcauca.com" rel="noreferrer" target="_blank">Léalos aquí</a>
                        </Checkbox>
                    </Form.Item>
                    
                    <Button type="primary" onClick={this.btnSiguiente} disabled={!this.state.terminos}>
                        Siguiente
                    </Button>
                </Form>
            </div>
        );
    }

    getPosition = (latitude,longitude) => {
        if( latitude !== undefined ){
            this.setState({latitude:latitude,longitude:longitude});
        }
    }

    frmMap = () => {
        return(
            <>
                <MapFrm clickHandler={this.getPosition} saveRegister={this.saveRegister} />
            </>
        );
    }

    btnSiguiente = () => {
        let { negocio,propietario,idpropietario,direccion,telefono } = this.state;

        if (negocio.trim() === '' || propietario.trim() === '' || idpropietario.trim() === '' || direccion.trim() === '' || telefono.trim() === ''){
            alert("Por favor complete la información de todos los campos");
        } else {
            this.setState({frmDatosVisible: false, frmMapVisible: true });
        }
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({successful: false});
    };

    saveRegister = async () => {
        const { negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,latitude,longitude } = this.state;
        await saveRegister(negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,latitude,longitude)
        .then(res => {
            if(res.id){
                //console.log("Fué creado");
                this.setState({successful: true});
            }else{
                console.log("No id== ",res);
            }
        })
        .catch(res => {
            alert("Error al guardar ",res.message);
        })

        this.setState({
            frmDatosVisible: true,
            frmMapVisible:false,
            negocio:'',
            propietario:'',
            idpropietario:'',
            direccion:'',
            telefono:'',
            celular:'',
            whatsapp:'',
            correo:'',
            terminos:false,
            latitude:null,
            longitude:null,
        });
    }

    modalSuccessful = () => {
        return (
            <Modal
                visible={this.state.successful}
                //visible={frmDatosVisible}
                maskClosable={true}
                footer={null}
                onCancel={this.handleCancel}
                style={{top:125}}
            >
                <div style={{backgroundColor:'#ffffff', borderRadius:10}}>
                    <div style={{display:'flex', backgroundColor: '#004a97', borderRadius:10, height:48, justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:21, color:'#ffffff'}}>Solicitud de Registro</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center', textAlign:'center',padding:'10%'}}>
                        <CheckCircleOutlined style={{fontSize:50, color:'#004a97'}} />
                        <br />
                        <h2>Registro Realizado</h2>
                        <p>Gracias por su registro, pronto nos contactaremos con usted para que haga parte de nuestras tiendas aliadas.</p>
                        <br />
                        <Button type="primary" htmlType="submit" onClick={this.handleCancel}>
                            Aceptar
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    render(){
        const { statusModal } = this.props;
        const { frmDatosVisible,frmMapVisible, successful } = this.state;
        
        /* console.log("== negocio: ",this.state.negocio);
        console.log("== propietario: ",this.state.propietario);
        console.log("== idpropietario: ",this.state.idpropietario);
        console.log("== direccion: ",this.state.direccion);
        console.log("== telefono: ",this.state.telefono);
        console.log("== terminos: ",this.state.terminos);
        console.log("== latitude: ",this.state.latitude);
        console.log("== longitude: ",this.state.longitude); */
        return (
            <>
                <Modal
                    visible={statusModal}
                    maskClosable={true}
                    footer={null}
                    onCancel={this.handleCancel}
                    style={{top:125}}
                >
                    <div style={{backgroundColor:'#ffffff', borderRadius:10}}>
                        <div style={{display:'flex', backgroundColor: '#004a97', borderRadius:10, height:48, justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:21, color:'#ffffff'}}>Solicitud de Registro</span>
                        </div>
                        {/* formulario registro de datos iniciales */}
                        { frmDatosVisible && this.frmDatos() }
                        {/* { this.frmDatos() } */}

                        {/* Mapa para elegir ubicación del negocio */}
                        { frmMapVisible && this.frmMap() }
                    </div>
                </Modal>

                {/* Modal para informar de correcto guardado de información */}
                { successful && this.modalSuccessful() }
            </>
        );
    };
}

RegistroNegocios.contextType = AppContext;

const mapStateToProps = (reducers) => {
    return reducers.registerReducer;
}

export default connect(mapStateToProps, registerActions)(RegistroNegocios);