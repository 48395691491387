import React, { Component } from 'react';
//import DrawerComp from './Drawer';

const logo = '/logo-header.png'; 

export default class Menu extends Component{
    render(){
        return(
            <>
                <div>
                    <div style={{position:'absolute'}}>
                        {/* <DrawerComp /> */}
                    </div>
                    <img style={{backgroundColor:'#004a97', paddingLeft:3, borderRadius:'50%'}} src={logo} alt='logo Licorera del Cauca'/>
                </div>
            </>
        );
    }
}