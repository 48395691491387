import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { loginUsr } from '../../Utils/Api';

export default function NormalLoginForm(){

const [logueo, setLogueo] = useState(null);
const [rutaInicial, setRutaInicial] = useState(null);
const [loading, setLoading] = useState(false);

const onLogin = values => {
    setLoading(true);
    loginUsr(values.username, values.password)
    .then(res => {
        sessionStorage.setItem('user',JSON.stringify(res));
        setLogueo(res);
        setLoading(false);
        if( res.user.role.type === 'adminilc'){
            setRutaInicial('/negocios');
        }else if( res.user.role.type === 'negocios'){
            setRutaInicial('/perfil-negocio');
        }
    })
    .catch(error => {
        setLoading(false);
        alert(error);
    })
};

  return (
    <>
        {(logueo !== null && rutaInicial !== null ) && 
            <Redirect to={{
                pathname: rutaInicial,
                }}
            />
        }
        <div style={{ marginTop:'15em' }}>
            <div style={{ maxWidth:300, margin:'0 auto' }}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: false }}
                    onFinish={onLogin}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Por favor ingrese el usuario' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Por favor ingrese la contraseña' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Contraseña"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} className="login-form-button">
                            Ingresar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </>
  );
};