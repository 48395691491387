import React, { Component } from 'react';
import { WhatsAppOutlined, PhoneOutlined, AimOutlined } from '@ant-design/icons';
import { Modal, Button, Select, Tooltip } from 'antd';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { _SERVER_, getAllShops } from '../../Utils/Api';
import Registro from '../RegistroNegocios';
import Products from './Products';
import './css.css';
import RegistroNegocios from '../RegistroNegocios';
import { AppContext } from '../../context/appContext';

import { connect } from 'react-redux';
import * as registerActions from '../actions/registerActions';

const { Option } = Select;

export class MapContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            width: 0,
            height: 0,
            activeMarker: {},
            points: null,
            selectedPlace:{name:'test',fotoUrl:"",telefono:'8222222',celular:'31111111', productos:[]},
            showingInfoWindow: false,
            visible_WindowInfo: false,
            visible_Register: false,
            zoom:14,
            latitude: 2.44189989,
            longitude: -76.60630077,
            currentLat: null,
            currentLng: null,
            onSearch: '',
            timeout: 0,
            currentValue: null,
            data: [],
            value: undefined,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.getCoordinates = this.getCoordinates.bind(this);
    }

    //obtiene info del backend para crear los marcadores
    getMarkers = () => {
        getAllShops('activos')
        .then(res => {
            //console.log("point== ",res);
            this.setState({points: res})
            this.setState({currentValue: res})
        })
        .catch(res => {
            alert(res.message);
        })
    }

    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoordinates);
        } else {
            alert("La geolocalización no está soportada en este navegador");
        }
    }

    //asigna la posición actual del usuario como centro del mapa
    getCoordinates = (position) => {
        this.setState({ zoom:17, latitude: position.coords.latitude, longitude: position.coords.longitude })
    }

    getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCurrentCoordinates);
        } else {
            alert("La geolocalización no está soportada en este navegador");
        }
    }

    //asigna las coordenadas actuales del usuario como centro del mapa
    getCurrentCoordinates = (position) => {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        this.setState({ zoom:18, currentLat: lat, currentLng: lng, latitude: lat, longitude: lng });
    }

    //activa el modal con información del marker del negocio
    showModal_WindowInfo = () => {
        this.setState({
            visible_WindowInfo: true,
        });
    };

    //activa el modal con formulario de registro "¿Quieres ver tu tienda aquí?"
    showModal_Register = () => {
        this.props.getStatusModal();
    };
    
    //acción cancelar de los modales
    handleCancel = e => {
        //console.log(e);
        this.setState({
            visible_WindowInfo: false,
            visible_Register: false,
        });
    };

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getMarkers();
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    //cuando se hace clic en las chinchetas
    onMarkerClick = (props, marker) => {
        this.showModal_WindowInfo()

        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            latitude: props.position.lat,
            longitude: props.position.lng
        });
    }

    //ventana que carga información de los negocios
    windowInfo = (visible) => {
        let whats = "https://wa.me/57"+ this.state.selectedPlace.whatsapp + "?text=¡Hola!,%20te%20encontr%C3%A9%20por%20Punto%20Caucano%20y%20quiero%20pedirte%20un%20domicilio";
        let tel = "tel:602" + this.state.selectedPlace.telefono;
        let cel = "tel:+57" + this.state.selectedPlace.celular;
        //console.log("data== ",this.state.selectedPlace);
        return(
            <Modal
                visible={visible}
                maskClosable={true}
                footer={null}
                onCancel={this.handleCancel}
                style={{top:125}}
            >
                <div style={styles.containerInfoWindow}>
                    { (this.state.selectedPlace.fotoUrl !== null && this.state.selectedPlace.fotoUrl !== '') 
                        ? <img src={this.state.selectedPlace.fotoUrl} alt={this.state.selectedPlace.name} style={{width:'100%',height:'100%'}} /> 
                        : <img src={"/picture_page_licores.png"} alt={this.state.selectedPlace.name} style={{width:'100%',height:'100%'}} />
                    }
                    <h1 style={{color:'#4B96F3',textAlign:'center',marginTop:10,fontSize: '1.5em'}}>{this.state.selectedPlace.name}</h1>
                    <div className="windowInfoContainer">
                        {(this.state.selectedPlace.whatsapp !== '' && this.state.selectedPlace.whatsapp !== null && this.state.selectedPlace.whatsapp !== '0') && <h3><a href={whats}><WhatsAppOutlined /> Escribir a WhatsApp</a></h3>}
                        {(this.state.selectedPlace.telefono !== '' && this.state.selectedPlace.telefono !== null && this.state.selectedPlace.telefono !== '0') && <h3><a href={tel}><PhoneOutlined /> Llamar al {this.state.selectedPlace.telefono}</a></h3>}
                        {(this.state.selectedPlace.celular !== '' && this.state.selectedPlace.celular !== null && this.state.selectedPlace.celular !== '0') && <h3><a href={cel}><PhoneOutlined /> Llamar al {this.state.selectedPlace.celular}</a></h3>}
                    </div>
                </div>
                    <Products productos={this.state.selectedPlace.productos}/>
            </Modal>
        );
    }

    registerForm = (visible) => {
        return(
            <Registro visible_Register={visible}/>
        )
    }

    //se carga los estilos personalizados del mapa
    _mapLoaded(mapProps, map) {
        map.setOptions({
           styles: mapStyle
        })
    }

    /* ==== Buscador ==== */
    handleChange = (value, option) => {
        if(option !== undefined){
            this.storeSelected(option.key)
        }
    };
    
    handleSearch = value => {
        if (value) {
            this.setState({data: this.currentValue})

            //vuelve a cargar toda la data para una nueva búsqueda, si no están cargados todos los puntos
            if(this.state.points !== this.state.currentValue){
                this.handleClear()
            }
        } else {
          this.setState({ data: [] });
        }
    };

    //vuelve a cargar toda la data para una nueva búsqueda
    handleClear = () => {
        this.setState({
            points: this.state.currentValue,
            data: this.state.currentValue
        })
    };

    //tienda selecionada
    storeSelected = (key) => {
        const negocio = this.state.points[key]
        this.setState({
            points: [negocio],
            selectedPlace: negocio,
            latitude: negocio.latitud,
            longitude: negocio.longitud,
        });
    }
    /* =============== */
    
    render() {
        let { zoom,points,visible_WindowInfo,visible_Register,latitude,longitude,currentLat,currentLng } = this.state;
        let urlPhoto = null;
        let options = null; // para el buscador

        if (!this.props.loaded) return <div>Cargando...</div>;

        if (points !== null) {
            options = points.map((srch, index) => <Option key={index} value={srch.nombre}>{srch.nombre}</Option>);
        }

        return (
            <>
                { this.windowInfo(visible_WindowInfo) }

                { this.registerForm(visible_Register) }

                <Map
                    className="map"
                    google={this.props.google} 
                    zoom={zoom}
                    style={{ width: this.state.width, height: (this.state.height - 64), marginTop:64  }}
                    fullscreenControl = {false}
                    mapTypeControl = {false}
                    streetViewControl = {false}
                    geoloccontrol = {true}
                    setMyLocationEnabled = {true}
                    initialCenter={{
                        lat: 2.44189989,
                        lng: -76.60630077
                    }}
                    center={{
                        lat: latitude,
                        lng: longitude,
                    }}
                    onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                    //onClick={this.onMarkerClick}
                >
                    <Tooltip placement="left" title="Ubicar mi localización">
                        <div className="btnLocation" onClick={this.getCurrentLocation} alt="Ubicar mi localización">
                            <AimOutlined style={{fontSize:30, padding:5}} />
                        </div>
                    </Tooltip>


                    {/* BUSCADOR */}
                    <div style={styles.searchContainer} className="searchContainer">
                        <Select
                            showSearch={true}
                            allowClear={true}
                            value={this.state.value}
                            placeholder={'Buscar un negocio'}
                            style={styles.buscador}
                            className="buscador"
                            defaultActiveFirstOption={false}
                            showArrow={true}
                            onSearch={this.handleSearch}
                            onChange={this.handleChange}
                            onClear={this.handleClear}
                            notFoundContent={null}
                            filterOption={true}
                        >
                            {options}
                        </Select>
                    </div>


                    <div className="contBtnRegistro">
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            <Button type="primary" shape="round" size="large" onClick={this.showModal_Register}>¿Quieres ver tu tienda aquí?</Button>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginRight:15,paddingRight:20}}>
                            <span style={{fontSize:11}}>Creado por <a href="https://www.stac.com.co" rel="noreferrer" target="_blank">Stac Tecnología S.A.S</a></span>
                        </div>
                    </div>
                    {points !== null && points.map((marker, index) => (
                        // eslint-disable-next-line no-sequences
                        (marker.fotografia_negocio !== null) ? urlPhoto = _SERVER_ + marker.fotografia_negocio.url : urlPhoto = "/picture_page_licores.png",
                        
                        <Marker
                            icon={{
                                url: "/pin-pc-64.png",
                                anchor: new this.props.google.maps.Point(32,32),
                                scaledSize: new this.props.google.maps.Size(64,64)
                            }}
                            key={index}
                            name={marker.nombre}
                            fotoUrl={urlPhoto}
                            telefono={marker.telefono}
                            celular={marker.celular}
                            whatsapp={marker.whatsapp}
                            productos={marker.productos}
                            onClick={this.onMarkerClick}
                            position={{ lat: marker.latitud, lng: marker.longitud }}
                        />
                    ))}

                    <Marker
                        icon={{
                            url: "/current-point.png",
                            anchor: new this.props.google.maps.Point(32,32),
                            scaledSize: new this.props.google.maps.Size(48,48)
                        }}
                        position={{  lat: currentLat, lng: currentLng }}
                    />  

                </Map>
            </>
        );
    }
}

RegistroNegocios.contextType = AppContext;

const mapStateToProps = (reducers) => {
    return reducers.registerReducer;
}

export default connect(mapStateToProps, registerActions)(GoogleApiWrapper({
    apiKey: ('AIzaSyAC6XFI-ubdlsgk_OEl-GviZJCYJehN0HA') // Producción
    //apiKey: ('AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo') // Desarrollo
})(MapContainer))

const styles = {
    containerInfoWindow: {
        maxWidth:600,
        position:'relative',
        padding:10,
        borderRadius: 10,
        backgroundColor: '#ffffff'
    },
    searchContainer: {
        width: '45%',
        position: 'absolute',
        top: '8vh',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        zIndex: 1000,
    },
    buscador: {
        width: '30vw',
        paddingLeft: 10
    }
}

/* Estilo y tipo de mapa */
const mapStyle = [
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#dceafa'
            }
        ]
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off"
            }
        ]
    }
]