import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { _SERVER_, getBanners } from '../../Utils/Api';

export default function Banner() {
    const [visible, setVisible] = useState(true);
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        getData();
    },[]);
    
    const getData = async () => {
        if(visible){
            await getBanners('activos')
            .then(res => {
                setBanners(res);
            })
            .catch(err => {
                alert(err.message);
            })
        }
    }

    const imagesBanner = (dataImgs) =>{
        if(dataImgs !== false){
            return(
                dataImgs.map((element, index) =>
                    <div key={index}>
                        <img src={_SERVER_ + element.imagen.url} alt={_SERVER_ + element.descripcion} />
                    </div>
                )
            )
        } else {
            return(
                <div style={styleLoading}>Cargando...</div>
            )
        }
    }
    
    return (
        <div>
            { (banners.length >= 1) &&
                <Modal
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width={'90%'}
                    footer={null}
                >
                    <Carousel
                        autoPlay
                        swipeable
                        infiniteLoop
                        emulateTouch
                        showThumbs = {false}
                        showStatus = {false}
                    >
                        { imagesBanner(banners) }
                    </Carousel>
                </Modal>
            }
        </div>
    )
}

const styleLoading = {
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 23,
    fontWeight: 'bold',
    borderRadius:20,
    backgroundColor: 'rgba(255,255,255,0.7)',
}