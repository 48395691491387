import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, message } from 'antd';
import { getAllPresentaciones, savePresentacion, updatePresentacion, deletePresentacion } from '../../Utils/Api';

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      //console.log('Error al guardar:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} es requerido.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class Presentaciones extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      count: 0,
      newItem: false,
      loadingTable: false,
    };

    this.columns = [
      {
        title: 'Nombre de presentación',
        dataIndex: 'nombre',
        width: '30%',
        editable: true,
      },
      {
        title: 'Acciones',
        dataIndex: 'operation',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm title="¿Eliminar definitivamente?" onConfirm={() => this.handleDelete(record.key)}>
              <a>Eliminar</a>
            </Popconfirm>
          ) : null,
      },
    ];
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = async () => {
    this.setState({loadingTable:true});
    await getAllPresentaciones()
    .then(res => {
      this.setState({ dataSource: res, count: res.length, loadingTable: false });
    })
    .catch(err => {
      this.setState({loadingTable:false});
      alert(err.message);
    })
  }

  handleDelete = idKey => {
    let key = 'updatable';
    const dataSource = [...this.state.dataSource];
    let delReg = dataSource.filter(item => item.key === idKey);

    message.loading({ content: 'Eliminando...', key });
    deletePresentacion(delReg[0].key)
    .then(res => {
      this.setState({
        dataSource: dataSource.filter(item => item.key !== idKey),
      });
      this.loadData();
      message.success({ content: 'Regsitro eliminado', key, duration: 3 });
    })
    .catch(err => {
      alert(err.message);
    })
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count + 1,
      nombre: `Clic aquí para editar`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
      newItem: true,
    });
  };

  handleSave = row => {
    const key = 'updatable';
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    
    if( this.state.newItem ){ //guarda un nuevo registro
      message.loading({ content: 'Guardando...', key });
      savePresentacion( newData[index].nombre )
      .then(res => {
        this.setState({
          dataSource: newData,
          newItem: false,
        });
        this.loadData();
        message.success({ content: '¡Guardado!', key, duration: 3 });
      })
      .catch(err =>{
        alert(err.message);
      })
    }else{ //actualiza un registro
      if(item.nombre !== newData[index].nombre) {
        message.loading({ content: 'Actualizando...', key });
        updatePresentacion( newData[index].key, newData[index].nombre )
        .then(res => {
          this.setState({
            dataSource: newData,
          });
          this.loadData();
          message.success({ content: '¡Actualizado!', key, duration: 3 });
        })
        .catch(err => {
          alert(err.message);
        })
      }
    }
  };

  render() {
    const { loadingTable, dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <div>
        <div style={{width:'100%', textAlign:'left',marginTop:18}}>
            <Button
                onClick={this.handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                Nueva presentación
            </Button>
        </div>

        <Table
          loading={loadingTable}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    );
  }
}