/* import React, { createContext } from 'react';

const Context = createContext();

export default Context; */


import React, { Component } from 'react';

export const AppContext = React.createContext({openModalRegister: false});

export class AppContextProvider extends Component {
    constructor() {
        super();
        
        this.state = {
            openModalRegister: true
        };

        this.toggleModalRegister = this.toggleModalRegister.bind(this);
    }

    toggleModalRegister = () => {
        this.setState({ openModalRegister: !this.state.openModalRegister });
    }

    render(){
        const { children } = this.props;
        const { openModalRegister } = this.state;
        return(
            <AppContext.Provider
                value={{
                    openModalRegister,
                }}
            >
                { children }
            </AppContext.Provider>
        );
    }
}

export const AppContextConsumer = AppContext.Consumer;