import React from 'react';
import BackendLayout from '../../Components/Base/BackendLayout/BackendLayout';
import CargaProductos from '../../Components/CargaProductos';

export default function Productos() {
    return (
        <BackendLayout>
            <CargaProductos />
        </BackendLayout>
    )
}