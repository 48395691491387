/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Card, Table, Space, Select, Modal, Form, Input, Button } from 'antd';
import { ShopOutlined,UserOutlined,IdcardOutlined,EnvironmentOutlined,PhoneOutlined,ShakeOutlined,WhatsAppOutlined,MailOutlined,LoadingOutlined,CheckCircleTwoTone,CloseCircleTwoTone,PoweroffOutlined } from '@ant-design/icons';
import { Redirect, useHistory } from 'react-router-dom';
import { _SERVER_, getShop, getAllProductos, updateRegister, updatePhoto } from '../../Utils/Api';
import { isAuth } from '../../Utils/Auth';

const { Option } = Select;

function PerfilNegocio(){
    const history = useHistory();

    const [points, setPoints] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    //const [loadingSwitch, setLoadingSwitch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false); //identifica si existió error en la respuesta del api al actualizar
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [idReg, setIdReg] = useState(null);
    const [negocio, setNegocio] = useState('test');
    const [propietario, setPropietario] = useState('test');
    const [idpropietario, setIdpropietario] = useState('test');
    const [direccion, setDireccion] = useState('test');
    const [telefono, setTelefono] = useState('test');
    const [celular, setCelular] = useState('test');
    const [whatsapp, setWhatsapp] = useState('test');
    const [correo, setCorreo] = useState('test');
    const [foto, setFoto] = useState([]);
    const [savedPhoto, setSavedPhoto] = useState('');
    const [producto, setProducto] = useState([]); //productos cargados de la tabla negocios o productos a guardar en la tabla negocios
    const [productos, setProductos] = useState([]); //lista de todos los productos cargados de la BD

    const singOut = () =>{
        let path = `/login`;
        sessionStorage.removeItem('user');
        history.push(path);
    }

    useEffect(() => {
        dataApi();
    },[]);

    const dataApi = async () => {
        let listProds = [];

        setLoadingTable(true);
        await getShop(isAuth().email)
        .then(res => {
            getAllProductos()
                .then(result => {
                    result.map((item) => {
                    listProds.push(<Option key={item.id}>{item.producto}</Option>);
                })
                setPoints(res);
                setProductos(listProds);
                setLoadingTable(false);
            })
            .catch(err => {
                alert(err.message);
            })
        })
        .catch(res => {
            setLoadingTable(false);
            alert("Problemas al cargar la información: ",res.message);
        })
    }

    const toggleModal = (record) => {
        setShowModal(!showModal);
        setIdReg(record.id);
        setNegocio(record.nombre);
        setPropietario(record.propietario);
        setIdpropietario(record.identificacion);
        setDireccion(record.direccion);
        setTelefono(record.telefono);
        setCelular(record.celular);
        setWhatsapp(record.whatsapp);
        setCorreo(record.correo);

        if( record.fotografia_negocio !== null && record.fotografia_negocio !== undefined && record.fotografia_negocio !== '' ){
            setSavedPhoto( _SERVER_ + record.fotografia_negocio.url );
        }else{
            setSavedPhoto( null );
        }

        if(record.productos !== null && record.productos !== undefined){
            setProducto( record.productos )
        }else{
            setProducto( [] )
        }

    }

    const onChangeNegocio = (event) => {
        setNegocio( event.target.value )
    }

    const onChangePropietario = (event) => {
        setPropietario( event.target.value );
    }

    const onChangeDireccion = (event) => {
        setDireccion( event.target.value );
    }

    const onChangeTelefono = (event) => {
        setTelefono( event.target.value );
    }

    const onChangeCelular = (event) => {
        setCelular( event.target.value );
    }

    const onChangeWhatsapp = (event) => {
        setWhatsapp( event.target.value );
    }

    const onChangeFoto = (event) => {
        setFoto( event.target.files );
    };

    const onChangeProductos = (value) => {
        setProducto( value );
    }

    const editInfo = () => {
        //const { loading,showModal,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,foto,savedPhoto,producto } = this.state;
        return(
        <Modal
            visible={showModal}
            maskClosable={true}
            footer={null}
            onCancel={toggleModal}
            style={{top:125}}
        >
            <div style={{padding:20, backgroundColor:'#fff', textAlign:'center'}}>
            <div>
                <h1 style={{fontSize:'1.5em'}}>Editando Registro</h1>
            </div>
            <Form
                //{...layout}
                name="registroLicorera"
                //onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
            >
                <div className="frmEditM">
                <Input placeholder="Nombre del negocio" id="negocio" prefix={<ShopOutlined style={{fontSize:21}} />} onChange={onChangeNegocio} value={negocio} />

                <Input placeholder="Nombre de propietario/a del negocio" id="propietario" prefix={<UserOutlined style={{fontSize:18}} />} onChange={onChangePropietario} value={propietario} />

                <Input placeholder="Dirección del negocio" id="direccion" prefix={<EnvironmentOutlined style={{fontSize:21}} />} onChange={onChangeDireccion} value={direccion} />

                <Input placeholder="Teléfono fijo del negocio" id="telefono" prefix={<PhoneOutlined style={{fontSize:21}} />} onChange={onChangeTelefono} value={telefono} />

                <Input placeholder="Número celular del negocio" id="celular" prefix={<ShakeOutlined style={{fontSize:21}} />} onChange={onChangeCelular} value={celular} />

                <Input placeholder="Número de whatsapp del negocio" id="whatsapp" prefix={<WhatsAppOutlined style={{fontSize:21}} />} onChange={onChangeWhatsapp} value={whatsapp} />

                <h3 style={{textAlign:'center',marginTop:7,marginBottom:0}}>Productos Caucano en venta</h3>
                <Select
                    mode="multiple"
                    placeholder="Clic aquí y elija los productos que vende"
                    onChange={onChangeProductos} value={producto}
                    style={{width:'100%', marginLeft:5, marginTop:5}}

                >
                    {productos}
                </Select>

                <h3 style={{textAlign:'center',marginTop:9,marginBottom:0}}>Fotografía del negocio</h3>
                <div style={{maxWidth:'100%', maxHeight:200, overflow:'hidden',margin:7}}>
                    {savedPhoto !== null ? <img src={savedPhoto} alt={negocio} style={{maxWidth:'100%',maxHeight:200}} /> : <h3 style={{padding:10, color:'#afafaf'}}>Sin imagen de referencia</h3>}
                </div>

                <input type="file" id="fileinput" accept="image/*" style={{marginLeft:5,marginTop:5}} onChange={onChangeFoto} />

                <Button type="primary" htmlType="submit" loading={loading} onClick={btnGuardar} style={{marginTop:5}}>
                    Guardar
                </Button>
                </div>
            </Form>
            </div>
        </Modal>
        )
    };

    const btnGuardar = async () => {
        //const { idReg,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,foto,producto } = this.state;

        setLoading( true );
        setShowModal( false );
        setShowResponseModal( true );

        await updateRegister(idReg,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,producto)
        .then(res => {
        //si se cargó foto
        if(foto.length > 0){
            //se actualiza la fotografía
            updatePhoto(res.id, foto)
            .then(result =>{
                setLoading( false );
                setErrorSaving( false );
                setFoto( [] );

                //actualiza data
                dataApi();
                })
            .catch(error => {
                setLoading( false );
                setErrorSaving( true );
                alert(error.message);
            })
        }else{
            setLoading( false );
            setErrorSaving( false );
            //actualiza data
            dataApi();
        }
        })
        .catch(err => {
            setLoading( false );
            setErrorSaving( true );
            alert(err.message);
        })
    }

    const responseModal = () => {
        //const { loading,errorSaving, showResponseModal } = this.state;

        const success = (
            <>
                <h1 style={{fontSize:'1.5em'}}>{loading ? 'Actualizando Registro...' : 'Actualizado'}</h1>
                <div>
                <CheckCircleTwoTone style={{ fontSize: '80px' }} twoToneColor="#52c41a" />
                <p style={{fontSize:15, marginTop:'1em'}}>El registro se actualizó correctamente.</p>
                </div>
            </>
        )
        const error = (
            <>
                <h1 style={{fontSize:'1.5em'}}>{loading ? 'Actualizando Registro...' : 'Error'}</h1>
                <div>
                <CloseCircleTwoTone style={{ fontSize: '80px' }} twoToneColor="#d03238"/>
                <p style={{fontSize:15, marginTop:'1em'}}>Hemos tenido problemas actualizando este registro, por favor intenta más tarde o informa al administrador del sistema.</p>
                </div>
            </>
        )

        return(
        <Modal
            visible={showResponseModal}
            maskClosable={false}
            footer={null}
            onCancel={()=> setShowResponseModal(false)}
            style={{top:125}}
        >
            <div style={{padding:20, backgroundColor:'#fff', textAlign:'center'}}>
            <div>
                <div>
                { loading && <h3>Procesando...</h3> }
                { loading && <LoadingOutlined style={{ fontSize: '50px', color: '#08c' }} /> }
                { (!loading && !errorSaving) && success }
                { (!loading && errorSaving) && error }
                </div>
            </div>
            <Button type="primary" disabled={loading} onClick={ ()=> setShowResponseModal(false) } style={{marginTop:5}}>
                Aceptar
            </Button>
            </div>
        </Modal>
        );
    }

    const columns = [
        {
            title: 'Nombre Negocio',
            dataIndex: 'nombre',
            key: 'nombre',
        },
        {
            title: 'Propietario/a',
            dataIndex: 'propietario',
            key: 'propietario',
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            key: 'celular',
        },
        {
            title: 'Acciones',
            render: (text, record) => (
            <Space size="middle">
                <a onClick={() => toggleModal(record)}>Editar </a>
            </Space>
            ),
        },
    ];

    return(
        <>
        {(sessionStorage.getItem('user') == null) &&
            <Redirect to={{
                pathname: '/login',
                }}
            />
        }
            <div style={{marginTop:100, alignSelf:'center', width:'80%'}}>
                <Card title="">
                    <h1 onClick={ singOut } className='closeSession' style={{textAlign:'right',paddingRight:10}}> <PoweroffOutlined /> Cerrar Sesión</h1>
                    <Card type="inner" title={"Edite la información de su negocio"}>
                        <Table rowKey="id" loading={loadingTable} columns={columns} dataSource={points} />

                        {/* modal con información a editar */}
                        { editInfo() }

                        {/* modal de respuesta de actualizar registro */}
                        { responseModal() }

                    </Card>
                </Card>
            </div>
        </>
    );

}

export default PerfilNegocio;
