import React, { Component } from 'react';
import { Carousel, Col } from 'antd';
import { _SERVER_, getAllProductos, getAllPresentaciones } from '../../../Utils/Api';
import { ObtieneCoincidentesID, ObtieneCoincidentesKEY } from '../../../Utils/Varios';
import './css.css';

export default class Products extends Component{

    constructor(props){
        super(props);

        this.state = {
            allProducts: [],
            allPresen: []
        }
    }

    componentDidMount = () => {
        this.dataApi();
    }

    dataApi = async () => {
        await getAllProductos()
        .then(result => {
            getAllPresentaciones()
            .then(res => {
                this.setState({ allProducts: result, allPresen: res });
            })
            .catch(error => {
                alert(error.message);
            })
        })
        .catch(err => {
            alert(err.message);
        })
    }

    render(){
        const { productos } = this.props;
        const { allProducts, allPresen } = this.state;
        let info = ObtieneCoincidentesID(allProducts, productos);
        let nameProducts = null;

        return(
            <div>
                { ( productos !== null && productos.length > 0 ) && 
                <>
                    <div className='TituloProductos'><h1 style={{fontSize:'1.3em',marginBottom:0, color:'#004a97 '}}>Productos Caucano</h1></div>
                    <Carousel autoplay={true}>
                        { info.map((item, index) => (
                            <div key={item.producto} className='divSlide'>
                                <Col>
                                    <img src={_SERVER_ + item.imagen[0].url} alt={item.producto} />
                                </Col>
                                <Col flex="auto" style={{height:170}}>
                                    <div className='contentProduct'>
                                        <h3>
                                            {item.producto}
                                        </h3>
                                        <p>
                                            {item.descripcion}
                                        </p>
                                        <h4>Presentaciones disponibles:</h4>
                                        <p>
                                            { 
                                                nameProducts = ObtieneCoincidentesKEY(allPresen, item.presentaciones).map(function(nom){return nom.nombre}),
                                                nameProducts.join(', ') 
                                            }
                                        </p>
                                    </div>
                                </Col>
                            </div>
                            )
                        )}
                    </Carousel>
                </>
                }
            </div>
        );
    }

}