import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

export default function NotFound(){
    return(
        <div style={{height:'100%', backgroundColor:'#fff', paddingTop:100}}>
            <Result
                status="404"
                title="404"
                subTitle="Lo sentimos, la página que buscas no existe."
                extra={<Link to="/" style={{backgroundColor:'#004a97', padding:7, color:'#fff'}}>Volver al Inicio</Link>}
            />
        </div>
    );
}