import React, { Component } from 'react';
import { Layout } from 'antd';
import Menu from '../Menu/';

const { Header } = Layout;

export default class AppLayout extends Component {
    render(){
        const { children } = this.props;
        return(
            <Layout style={{zIndex:999}}>
                <Header style={styles.header}>
                    <Menu />
                </Header>
                <Layout>
                    { children }
                </Layout>
            </Layout>
        );
    }
}

const styles = {
    header: {
        zIndex:1000,
        width:'100%',
        position:'absolute',
        backgroundColor:'#004a97 '//'rgba(24,72,135,1)'
    }
}