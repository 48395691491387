/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Table, Space, Popconfirm, Modal, Form, Input, Button, message } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { _SERVER_, updatePhotoBanner, getAllBanners, saveBanner, updateBanner, deleteBanner } from '../../Utils/Api';

export default class AgregarBanners extends Component {
    constructor(props){
      super(props);

      this.state = {
          points: null,
          loading: false,
          loadingTable: false,
          loadingSwitch: false,
          showModal: false,
          errorSaving: false, //identifica si existió error en la respuesta del api al actualizar
          showResponseModal: false,
          idReg: null,
          descripcion: '',
          foto: [],
          savedPhoto:'',  //fotografía guardada en BD
          newItem: false,
      }
    }

    componentDidMount = () => {
        this.dataApi();
    };
    
    dataApi = async () => {
        this.setState({loadingTable:true});
        await getAllBanners()
        .then(res => {
            //console.log(res);
            this.setState({points: res, loadingTable: false});
        })
        .catch(res => {
            this.setState({loadingTable:false});
            alert("Problemas al cargar la información: ",res.message);
        })
    }

    toggleModal = () => {
        this.setState({ 
            showModal: !this.state.showModal,
            savedPhoto: null,
        });
    }

    onChangeDescripcion = (event) => {
        this.setState({descripcion: event.target.value});
    }

/*     onChangeDescripcion = (event) => {
        this.setState({descripcion: event.target.value});
    } */
    
/*     onChangePresentacion = (value) => {
        this.setState({presentacion: value});
    } */

    onChangeFoto = (event) => {
        //console.log("image== ",event.target.files)
        this.setState({foto: event.target.files});
    };

    editInfo = () => {
      const { loading,showModal,newItem,descripcion,savedPhoto } = this.state;
      return(
        <Modal
            visible={showModal}
            maskClosable={true}
            footer={null}
            onCancel={this.toggleModal}
            style={{top:125}}
        >
          <div style={{padding:20, backgroundColor:'#fff', textAlign:'center'}}>
              <div>
                  { newItem ? <h1 style={{fontSize:'1.5em'}}>Nuevo Registro</h1> : <h1 style={{fontSize:'1.5em'}}>Editando Registro</h1> }
              </div>
              <Form
                name="registroBanner"
              >
                <div className="frmEditM">
                    <Input placeholder="Descripción corta" id="descripcion" prefix={<TagOutlined style={{fontSize:21}} />} onChange={this.onChangeDescripcion} value={descripcion} />

                    <div style={{maxWidth:'100%', maxHeight:200, overflow:'hidden',margin:7}}>
                        {savedPhoto !== null ? <img src={savedPhoto} alt={descripcion} style={{maxWidth:'100%',maxHeight:200}} /> : <h3 style={{padding:10, color:'#afafaf'}}>Sin imagen para banner</h3>}
                    </div>

                    <input type="file" id="fileinput" accept="image/*" style={{marginLeft:5,marginTop:5}} onChange={this.onChangeFoto} />

                    { newItem
                        ?
                            <Button type="primary" htmlType="submit" loading={loading} onClick={this.btnGuardar} style={{marginTop:5}}>
                                Guardar
                            </Button>
                        :
                            <Button type="primary" htmlType="submit" loading={loading} onClick={this.btnUpdate} style={{marginTop:5}}>
                                Actualizar
                            </Button>
                    }
                </div>
              </Form>
          </div>
        </Modal>
      )
    };

    btnNuevo = () => {
        this.setState({ newItem: true, descripcion:'' });
        this.toggleModal();
    }

    btnGuardar = async () => {
        let key = 'updatable';
        const { descripcion, foto } = this.state;

        this.setState({ loading:true });

        message.loading({ content: 'Guardando...', key });
        saveBanner( descripcion )
        .then(res => {
            //si se cargó foto
            if(foto.length > 0){
                //se actualiza la fotografía
                updatePhotoBanner(res.id, foto)
                .then(result =>{
                    this.setState({loading:false, errorSaving:false, foto:[]});
                    //actualiza data
                    this.toggleModal();
                    this.dataApi();
                    message.success({ content: 'Regsitro Guardado', key, duration: 3 });
                })
                .catch(error => {
                    this.setState({loading:false, errorSaving:true});
                    this.toggleModal();
                    alert(error.message);
                })
            }else{
                this.setState({loading:false, errorSaving:false});
                this.toggleModal();
                //actualiza data
                this.dataApi();
                message.success({ content: 'Regsitro Guardado', key, duration: 3 });
            }
        })
        .catch(err => {
            this.setState({loading:false, showModal:false});
            alert(err.message);
        })
    }

    btnEdit = (record) => {
        //console.log("edit== ",record)
        this.setState({
            newItem: false,
            idReg: record.id,
            descripcion: record.descripcion,
        });
        this.toggleModal();

        if( record.imagen !== null && record.imagen !== undefined && record.imagen !== '' ){
            if( record.imagen ){
                this.setState({
                    savedPhoto: _SERVER_ + record.imagen.url
                });
            }else{
                this.setState({ savedPhoto: null });
            }
        }else{
            this.setState({ savedPhoto: null });
        }
    }

    btnUpdate = () => {
        let key = 'updatable';
        const { idReg, descripcion, foto } = this.state;
        this.setState({loading:true});

        //console.log(descripcion)
        message.loading({ content: 'Actualizando...', key });
        updateBanner( idReg, descripcion )
        .then(res => {
            //si se cargó foto
            if(foto.length > 0){
                //se actualiza la fotografía
                updatePhotoBanner(res.id, foto)
                .then(result =>{
                    this.setState({loading:false, errorSaving:false, foto:[]});
                    //actualiza data
                    this.toggleModal();
                    this.dataApi();
                    message.success({ content: 'Regsitro Actualizado', key, duration: 3 });
                })
                .catch(error => {
                    this.setState({loading:false, errorSaving:true});
                    this.toggleModal();
                    alert(error.message);
                })
            }else{
                this.setState({loading:false, errorSaving:false});
                this.toggleModal();
                //actualiza data
                this.dataApi();
                message.success({ content: 'Regsitro Actualizado', key, duration: 3 });
            }
            //se resetea el id del registro editado
            this.setState({ idReg:null });
        })
        .catch(err => {
            //se resetea el id del registro editado
            this.setState({ idReg:null });
            alert(err.message);
        })
    }

    btnDelete = idKey => {
        let key = 'updatable';
        const dataSource = [...this.state.points];
        let delReg = dataSource.filter(item => item.id === idKey);
    
        message.loading({ content: 'Eliminando...', key });
        deleteBanner(delReg[0].id)
        .then(res => {
          this.setState({
            dataSource: dataSource.filter(item => item.id !== idKey),
          });
          this.dataApi();
          message.success({ content: 'Regsitro eliminado', key, duration:3 });
        })
        .catch(err => {
          alert(err.message);
        })
    };

    render(){
        const columns = [
            {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                //render: text => <a>{text}</a>,
            },
            {
                title: 'Acciones',
                render: (text, record) => (
                    <>
                        <Space size="middle">
                            <a onClick={() => this.btnEdit(record)}>Editar </a>

                            { this.state.points.length >= 1 ? (
                                <Popconfirm title="¿Eliminar definitivamente?" onConfirm={() => this.btnDelete(record.id)}>
                                    <a>Eliminar</a>
                                </Popconfirm>
                            ) : null }
                        </Space>
                    </>
                ),
            },
        ];

        return(
            <>
                <div style={{width:'100%', textAlign:'left',marginTop:18}}>
                    <Button
                        onClick={this.btnNuevo}
                        type="primary"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        Nuevo Banner
                    </Button>
                </div>

                <Table rowKey="id" loading={this.state.loadingTable} columns={columns} dataSource={this.state.points} />
                
                {/* modal con información a editar */}
                { this.editInfo() }
            </>
        )
    };
}