import React from 'react';
import BackendLayout from '../../Components/Base/BackendLayout/BackendLayout';
import Tabla from '../../Components/Base/Tabla';

export default function Negocios() {
    return (
        <BackendLayout>
            <Tabla />
        </BackendLayout>
    )
}