
/* 
       ObtieneCoincidentesID:
       Busca en un array complejo con objetos (listaCompleta) con otro array simple (listaDeBusqueda)
       para encontrar los -->'id'<-- del array complejo con los items del array simple. Devolviendo los objetos
       completos del array complejo 
    */
    export function ObtieneCoincidentesID (listaCompleta, listaDeBusqueda) {

        let pos = 0;
        let objetosEncontrados = [];

        if( Array.isArray(listaCompleta) && Array.isArray(listaDeBusqueda) ){
            for( let w=0; w < listaDeBusqueda.length; w++ ){
                //let esta = false
                for( let n=0; n < listaCompleta.length; n++ ){
                    if( listaDeBusqueda[w] == listaCompleta[n].id ){
                        //esta = true
                        objetosEncontrados[pos] = listaCompleta[n];
                        pos = pos + 1
                    }
                }
                /* if (esta){
                    datosNoRepetidos[pos]=productos[w];
                    pos = pos + 1
                } */
            }
        } else {
            return []
        }

        return objetosEncontrados;
    }

    /* 
       ObtieneCoincidentesKey:
       Busca en un array complejo con objetos (listaCompleta) con otro array simple (listaDeBusqueda)
       para encontrar los -->'key'<-- del array complejo con los items del array simple. Devolviendo los objetos
       completos del array complejo 
    */
    export function ObtieneCoincidentesKEY (listaCompleta, listaDeBusqueda) {
        let pos = 0;
        let objetosEncontrados = [];
        if( Array.isArray(listaCompleta) && Array.isArray(listaDeBusqueda) ){
            for( let w=0; w < listaDeBusqueda.length; w++ ){
                //let esta = false
                for( let n=0; n < listaCompleta.length; n++ ){
                    if( listaDeBusqueda[w] == listaCompleta[n].key ){
                        //esta = true
                        objetosEncontrados[pos] = listaCompleta[n];
                        pos = pos + 1
                    }
                }
                /* if (esta){
                    datosNoRepetidos[pos]=productos[w];
                    pos = pos + 1
                } */
            }
        } else {
            return []
        }
    return objetosEncontrados;
    }