/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Table, Space, Popconfirm, Switch, Select, Modal, Form, Input, Button, message } from 'antd';
import { ShopOutlined,UserOutlined,IdcardOutlined,EnvironmentOutlined,PhoneOutlined,ShakeOutlined,WhatsAppOutlined,MailOutlined,LoadingOutlined,CheckCircleTwoTone,CloseCircleTwoTone } from '@ant-design/icons';
import { _SERVER_, getAllShops, getAllProductos, updateRegister, updateRegisterStatus, updatePhoto, verifyUser, createBusinessUser, updateStatusBusinessUser, updatePasswordBusinessUser, deleteRegister } from '../../../Utils/Api';
import { genSecureToken } from '../../../Utils/Auth';
import './css.css';

const { Option } = Select;
const Mensage = message;

const sendEmail = async  (email, userName = "Punto Caucano - ILC", pws) => {
  return await fetch("/api/send_email", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, userName, pws })
  }).then(response => response.json());
};

export default class Tabla extends Component {
  constructor(props){
    super(props);

    this.state = {
      points: null,
      loading: false,
      loadingTable: false,
      loadingSwitch: false,
      showModal: false,
      errorSaving: false, //identifica si existió error en la respuesta del api al actualizar
      showResponseModal: false,
      idReg: null,
      negocio: 'test',
      propietario: 'test',
      idpropietario: 'test',
      direccion: 'test',
      telefono: 'test',
      celular: 'test',
      whatsapp: 'test',
      correo: 'test',
      foto: [],
      savedPhoto:'',  //fotografía guardada en BD
      producto: [], //productos cargados de la tabla negocios o productos a guardar en la tabla negocios
      productos: [] //lista de todos los productos cargados de la BD
    }
  }

  componentDidMount = () => {
    this.dataApi();
  };
  
  dataApi = async () => {
    let listProds = [];

    this.setState({loadingTable: true});
    await getAllShops('todos')
    .then(res => {
      getAllProductos()
      .then(result => {
        result.map((item) => {
          listProds.push(<Option key={item.id}>{item.producto}</Option>);
        })
        this.setState({points: res, productos: listProds, loadingTable: false});
      })
      .catch(err => {
        alert(err.message);
      })
    })
    .catch(res => {
      this.setState({loadingTable: false});
      alert("Problemas al cargar la información: ",res.message);
    })
  }

  toggleModal = (record) => {
    this.setState({
      showModal: !this.state.showModal,
      idReg: record.id,
      negocio: record.nombre,
      propietario: record.propietario,
      idpropietario: record.identificacion,
      direccion: record.direccion,
      telefono: record.telefono,
      celular: record.celular,
      whatsapp: record.whatsapp,
      correo: record.correo,
    });

    if( record.fotografia_negocio !== null && record.fotografia_negocio !== undefined && record.fotografia_negocio !== '' ){
      this.setState({
        savedPhoto: _SERVER_ + record.fotografia_negocio.url
      });
    }else{
      this.setState({ savedPhoto: null });
    }

    if(record.productos !== null && record.productos !== undefined){
      this.setState({ producto: record.productos });
    }else{
      this.setState({ producto: [] });
    }

  }

  onChangeNegocio = (event) => {
    this.setState({negocio:event.target.value});
  }

  onChangePropietario = (event) => {
    this.setState({propietario: event.target.value});
  }

  onChangeIdPropietario = (event) => {
    this.setState({idpropietario: event.target.value});
  }

  onChangeDireccion = (event) => {
    this.setState({direccion: event.target.value});
  }

  onChangeTelefono = (event) => {
    this.setState({telefono: event.target.value});
  }

  onChangeCelular = (event) => {
    this.setState({celular: event.target.value});
  }

  onChangeWhatsapp = (event) => {
    this.setState({whatsapp: event.target.value});
  }

  onChangeCorreo = (event) => {
    this.setState({correo: event.target.value});
  }

  onChangeFoto = (event) => {
    this.setState({foto: event.target.files});
  };

  onChangeProductos = (value) => {
    this.setState({producto: value});
  }

  editInfo = () => {
    const { loading,showModal,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,foto,savedPhoto,producto } = this.state;
    return(
      <Modal
          visible={showModal}
          maskClosable={true}
          footer={null}
          onCancel={this.toggleModal}
          style={{top:125}}
      >
        <div style={{padding:20, backgroundColor:'#fff', textAlign:'center'}}>
          <div>
            <h1 style={{fontSize:'1.5em'}}>Editando Registro</h1>
          </div>
          <Form
              //{...layout}
              name="registroLicorera"
              //onFinish={onFinish}
              //onFinishFailed={onFinishFailed}
          >
            <div className="frmEditM">
              <Input placeholder="Nombre del negocio" id="negocio" prefix={<ShopOutlined style={{fontSize:21}} />} onChange={this.onChangeNegocio} value={negocio} />
            
              <Input placeholder="Nombre de propietario/a del negocio" id="propietario" prefix={<UserOutlined style={{fontSize:18}} />} onChange={this.onChangePropietario} value={propietario} />

              <Input placeholder="Número de identificación de propietario/a" id="idpropietario" prefix={<IdcardOutlined style={{fontSize:21}} />} onChange={this.onChangeIdPropietario} value={idpropietario} />

              <Input placeholder="Dirección del negocio" id="direccion" prefix={<EnvironmentOutlined style={{fontSize:21}} />} onChange={this.onChangeDireccion} value={direccion} />

              <Input placeholder="Teléfono fijo del negocio" id="telefono" prefix={<PhoneOutlined style={{fontSize:21}} />} onChange={this.onChangeTelefono} value={telefono} />

              <Input placeholder="Número celular del negocio" id="celular" prefix={<ShakeOutlined style={{fontSize:21}} />} onChange={this.onChangeCelular} value={celular} />
              
              <Input placeholder="Número de whatsapp del negocio" id="whatsapp" prefix={<WhatsAppOutlined style={{fontSize:21}} />} onChange={this.onChangeWhatsapp} value={whatsapp} />

              <Input placeholder="Correo electrónico para notificaciones" id="correo" prefix={<MailOutlined style={{fontSize:21}} />} onChange={this.onChangeCorreo} value={correo} />

              <h3 style={{textAlign:'center',marginTop:7,marginBottom:0}}>Productos Caucano en venta</h3>
              <Select
                mode="multiple"
                placeholder="Clic aquí y elija los productos que vende"
                onChange={this.onChangeProductos} value={producto}
                style={{width:'100%', marginLeft:5, marginTop:5}}
                  
              >
                {this.state.productos}
              </Select>
              
              <h3 style={{textAlign:'center',marginTop:9,marginBottom:0}}>Fotografía del negocio</h3>
              <div style={{maxWidth:'100%', maxHeight:200, overflow:'hidden',margin:7}}>
                {savedPhoto !== null ? <img src={savedPhoto} alt={negocio} style={{maxWidth:'100%',maxHeight:200}} /> : <h3 style={{padding:10, color:'#afafaf'}}>Sin imagen de referencia</h3>}
              </div>

              <input type="file" id="fileinput" accept="image/*" style={{marginLeft:5,marginTop:5}} onChange={this.onChangeFoto} />

              <Button type="primary" htmlType="submit" loading={loading} onClick={this.btnGuardar} style={{marginTop:5}}>
                Guardar
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  };

  btnGuardar = async () => {
    const { idReg,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,foto,producto } = this.state;

    this.setState({loading:true, showModal:false, showResponseModal:true});

    await updateRegister(idReg,negocio,propietario,idpropietario,direccion,telefono,celular,whatsapp,correo,producto)
    .then(res => {
      //si se cargó foto
      if(foto.length > 0){
        //se actualiza la fotografía
        updatePhoto(res.id, foto)
        .then(result =>{
          //actualiza data
          this.dataApi();
          this.setState({loading:false, errorSaving:false, foto:[]});
        })
        .catch(error => {
          this.setState({loading:false, errorSaving:true});
          alert(error.message);
        })
      }else{
        this.setState({loading:false, errorSaving:false});
        //actualiza data
        this.dataApi();
      }
    })
    .catch(err => {
      this.setState({loading:false, errorSaving:true});
      alert(err.message);
    })
  }

  btnDelete = idKey => {
    let key = 'updatable';
    const dataSource = [...this.state.points];
    let delReg = dataSource.filter(item => item.id === idKey);

    message.loading({ content: 'Eliminando...', key });
    deleteRegister(delReg[0].id)
    .then(res => {
      this.setState({
        dataSource: dataSource.filter(item => item.id !== idKey),
      });
      this.dataApi();
      message.success({ content: 'Regsitro eliminado', key, duration:3 });
    })
    .catch(err => {
      alert(err.message);
    })
  };

  responseModal = () => {
    const { loading,errorSaving, showResponseModal } = this.state;

    const success = (
      <>
        <h1 style={{fontSize:'1.5em'}}>{loading ? 'Actualizando Registro...' : 'Actualizado'}</h1>
        <div>
          <CheckCircleTwoTone style={{ fontSize: '80px' }} twoToneColor="#52c41a" />
          <p style={{fontSize:15, marginTop:'1em'}}>El registro se actualizó correctamente.</p>
        </div>
      </>
    )
    const error = (
      <>
        <h1 style={{fontSize:'1.5em'}}>{loading ? 'Actualizando Registro...' : 'Error'}</h1>
        <div>
          <CloseCircleTwoTone style={{ fontSize: '80px' }} twoToneColor="#d03238"/>
          <p style={{fontSize:15, marginTop:'1em'}}>Hemos tenido problemas actualizando este registro, por favor intenta más tarde o informa al administrador del sistema.</p>
        </div>
      </>
    )
    
    return(
      <Modal
          visible={showResponseModal}
          maskClosable={false}
          footer={null}
          onCancel={()=> this.setState({showResponseModal:false})}
          style={{top:125}}
      >
        <div style={{padding:20, backgroundColor:'#fff', textAlign:'center'}}>
          <div>
            <div>
              { loading && <h3>Procesando...</h3> }
              { loading && <LoadingOutlined style={{ fontSize: '50px', color: '#08c' }} /> }
              { (!loading && !errorSaving) && success }
              { (!loading && errorSaving) && error }
            </div>
          </div>
          <Button type="primary" disabled={loading} onClick={ ()=> this.setState({showResponseModal:false}) } style={{marginTop:5}}>
            Aceptar
          </Button>
        </div>
      </Modal>
    );
  }

  onChangeSwitch (record, status) {
    this.setState({ loadingSwitch:true });

    updateRegisterStatus(record.id, !status)
    .then(res => {
      //verifica si el negocio tiene un usuario creado
      verifyUser(record.identificacion)
      .then(result => {
        if( Array.isArray(result) ){
          //bloquea o desbloquea la cuenta de usuario del negocio
          //de acuerdo al status del visibilidad en el mapa
          if( res.activo ){
            updateStatusBusinessUser(result[0].id,false); //bloqueo en falso
          }else if( !res.activo ){
            updateStatusBusinessUser(result[0].id,true); //bloqueo en true
          }
        }else if( result === false ){
          let newPws = genSecureToken();
          //crea el usuario y genera contraseña aleatoria
          createBusinessUser(record.identificacion, record.correo, newPws, !res.activo )
          .then(resultUser => {
            //envía correo con los datos de acceso
            this.enviaCorreo(res.correo,newPws)
          })
          .catch(errorUser => {
            alert(errorUser.message);
          })
        }
      })
      .catch(error => {
        alert(error.message);
      })

      this.setState({ loadingSwitch:false });

      //actualiza data
      this.dataApi();
    })
    .catch(err => {
      this.setState({ loadingSwitch:false });
      alert(err.message);
    })
  }

  loadingSwitch = () => {
    const { loadingSwitch } = this.state;
    
    return(
      <Modal
          visible={loadingSwitch}
          maskClosable={false}
          footer={null}
          onCancel={()=> this.setState({showResponseModal:false})}
          style={{top:125}}
      >
        <div style={{padding:20, backgroundColor:'#fff', textAlign:'center', borderRadius:10}}>
          <h3>Procesando...</h3>
          { loadingSwitch && <LoadingOutlined style={{ fontSize: '50px', color: '#08c' }} /> }
        </div>
      </Modal>
    );
  }

  enviaCorreo = (email,pws) => {
    sendEmail(email,pws)
    .then(({ message }) => {
      Mensage.info(message);
    });
  }

  resetPasswordBusinessAcount = (record) => {
    //verifica si el negocio tiene un usuario creado
    verifyUser(record.identificacion)
    .then(result => {
      if( Array.isArray(result) ){
        let newPws = genSecureToken();
        //cambia contraseña y envía correo
        updatePasswordBusinessUser(result[0].id,newPws)
        .then(resUpdUser => {
          //envía correo con los datos de acceso
          this.enviaCorreo(record.correo,newPws)
        })
        .catch(errUpdUser => {
          alert(errUpdUser.message);
        })
      }else if( result === false ){
        let newPws = genSecureToken();
        //crea el usuario y genera contraseña aleatoria
        createBusinessUser(record.identificacion, record.correo, newPws, false )
        .then(resultUser => {
          //envía correo con los datos de acceso
          this.enviaCorreo(record.correo,newPws)
        })
        .catch(errorUser => {
          alert(errorUser.message);
        })
      }
    })
    .catch(error => {
      alert(error.message);
    })
  }

  render(){
    const columns = [
      {
        title: 'Nombre Negocio',
        dataIndex: 'nombre',
        /* defaultSortOrder: 'ascend',
        sorter: (a, b) => a.nombre - b.nombre, */
        key: 'nombre',
        //render: text => <a>{text}</a>,
      },
      {
        title: 'Propietario/a',
        dataIndex: 'propietario',
        key: 'propietario',
      },
      {
        title: 'Celular',
        dataIndex: 'celular',
        key: 'celular',
      },
      {
        title: 'Activo',
        key: 'activo',
        dataIndex: 'activo',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.activo - b.activo,
        render: (tags, record) => (
          <>
              <Switch checked={tags} onChange={() => this.onChangeSwitch(record, tags)} />
          </>
        )
        /* render: tags => (
          <>
            {tags.map(tag => {
              let color = tag.length > 5 ? 'geekblue' : 'green';
              if (tag === 'loser') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ), */
      },
      {
        title: 'Acciones',
        render: (text, record) => (
          <Space size="middle">
            <a onClick={() => this.toggleModal(record)}>Editar </a>
            <a onClick={() => this.resetPasswordBusinessAcount(record)}>Resetear Contraseña </a>
            { this.state.points.length >= 1 ? (
                                <Popconfirm title="¿Eliminar definitivamente?" onConfirm={() => this.btnDelete(record.id)}>
                                    <a>Eliminar</a>
                                </Popconfirm>
                            ) : null }
          </Space>
        ),
      },
    ];

    return(
      <>
        <Table rowKey="id" loading={this.state.loadingTable} columns={columns} dataSource={this.state.points} />
        
        {/* modal con información a editar */}
        { this.editInfo() }

        {/* modal de respuesta de actualizar registro */}
        { this.responseModal() }

        {/* loader del switche - activo - */}
        { this.loadingSwitch() }
      </>
    )
  };
}