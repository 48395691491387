import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import MenuBackend from '../MenuBackend';

const { Content, Footer, Sider } = Layout;

export default class Ingreso extends Component{
    render(){
        return(
            <>
            {(sessionStorage.getItem('user') == null) && 
                <Redirect to={{
                    pathname: '/login',
                    }}
                />
            }
                <Content style={{ padding: '0 50px',margin: '24px 16px 0' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
                        <Sider className="site-layout-background" width={200}>
                            <MenuBackend />
                        </Sider>
                        <Content style={{ padding: '30px', minHeight: 280 }}>
                            { this.props.children }
                        </Content>
                    </Layout>
                    <Footer style={{ textAlign: 'center' }}>©2020 Stac Tecnología S.A.S</Footer>
                </Content>
            </>
        );
    }
}