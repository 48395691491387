import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { ShopOutlined, InboxOutlined, PoweroffOutlined } from '@ant-design/icons';
import './css.css';

const { SubMenu } = Menu;

export default function MenuBackend() {
    const history = useHistory();

    const singOut = () =>{ 
        let path = `/login`; 

        sessionStorage.removeItem('user');
        history.push(path);
    }

    return (
        <Menu
            mode="inline"
            //defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1','sub2']}
            style={{ height: '100%', paddingTop:30 }}
        >
            <SubMenu key="sub1" icon={<ShopOutlined />} title="Negocios">
                <Menu.Item key="1"><NavLink activeClassName="ant-menu-item ant-menu-item-only-child ant-menu-item-selected" to="/negocios">Registrados</NavLink></Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<InboxOutlined />} title="Productos">
                <Menu.Item key="2"><NavLink activeClassName="ant-menu-item ant-menu-item-only-child ant-menu-item-selected" to="/productos">Productos</NavLink></Menu.Item>
                <Menu.Item key="3"><NavLink activeClassName="ant-menu-item ant-menu-item-only-child ant-menu-item-selected" to="/presentaciones">Presentaciones</NavLink></Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<ShopOutlined />} title="Otros">
                <Menu.Item key="4"><NavLink activeClassName="ant-menu-item ant-menu-item-only-child ant-menu-item-selected" to="/banners">Banners</NavLink></Menu.Item>
            </SubMenu>
            <div onClick={ singOut } className='closeSession'> <PoweroffOutlined /> Cerrar Sesión</div>
        </Menu>
    )
};