import React from 'react';
import { Layout } from 'antd';
import Map from '../Components/Map';
import Banner from '../Components/Banner';

function Home(){
    return(
        <Layout>
            <Banner />
            <Map />
        </Layout>
    );
}

export default Home;