import React, { Component } from 'react';
import { Layout } from 'antd';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import AppLayout from './Components/Base/AppLayout/AppLayout';

/* Protege rutas */
//import PrivateRoute from './Utils/PrivateRoute';

/* Páginas */
/* import Map from './Components/Map'; */
import Home from './Pages/Home';
import Login from './Pages/Login/Login';
import Negocios from './Pages/Ingreso/Negocios';
import Productos from './Pages/Productos';
import AgregarBanners from './Pages/AgregarBanners';
import Presentaciones from './Pages/Presentaciones';
import PerfilNegocio from './Components/PerfilNegocio';
import Admin from './Pages/Admin';
import NotFound from './Pages/404';

class App extends Component{
  render(){
    return (
        <Layout className="App">
          <BrowserRouter>
            <AppLayout>
              <Switch>
                <Route path="/" exact={true}>
                  <Home />
                </Route>
                <Route path="/login" exact={true}>
                  <Login />
                </Route>
                <Route path="/admin" exact={true}>
                  <Admin />
                </Route>
                <Route path="/negocios" exact={true}>
                  <Negocios />
                </Route>
                <Route path="/productos" exact={true} >
                  <Productos/>
                </Route>
                <Route path="/presentaciones" exact={true} >
                  <Presentaciones/>
                </Route>
                <Route path="/perfil-negocio" exact={true} >
                  <PerfilNegocio/>
                </Route>
                <Route path="/banners" exact={true} >
                  <AgregarBanners/>
                </Route>
                
                <Route component={NotFound} />
              </Switch>
            </AppLayout>
          </BrowserRouter>
        </Layout>
    );
  }
}

export default App;