const INITIAL_STATE = {
    statusModal: false
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'statusModal':
            return { ...state, statusModal: action.payload }

            default: return state;
    }
}