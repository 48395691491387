/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Table, Space, Popconfirm, Modal, Form, Input, Select, Button, message } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { _SERVER_, updatePhotoProducto, getAllProductos, getAllPresentaciones, saveProducto, updateProducto, deleteProducto } from '../../Utils/Api';
import './css.css';

const { TextArea } = Input;
const { Option } = Select;

export default class CargaProductos extends Component {
    constructor(props){
        super(props);

        this.state = {
            points: null,
            dataPresentaciones: [],
            loading: false,
            loadingTable: false,
            loadingSwitch: false,
            showModal: false,
            errorSaving: false, //identifica si existió error en la respuesta del api al actualizar
            showResponseModal: false,
            idReg: null,
            producto: '',
            descripcion: '',
            presentacion: [],
            foto: [],
            savedPhoto:'',  //fotografía guardada en BD
            newItem: false,
        }
    }

    componentDidMount = () => {
        this.dataApi();
    };
    
    dataApi = async () => {
        let listPresen = [];
        this.setState({loadingTable:true});
        await getAllProductos()
        .then(res => {
            console.log(res);
            getAllPresentaciones()
            .then(resul => {
                // eslint-disable-next-line array-callback-return
                resul.map((item) => {
                    listPresen.push(<Option key={item.key}>{item.nombre}</Option>);
                })
                //console.log(listPresen);
                this.setState({points: res, presentaciones: listPresen, loadingTable: false});
            })
        })
        .catch(res => {
            this.setState({loadingTable:false});
            alert("Problemas al cargar la información: ",res.message);
        })
    }

    toggleModal = () => {
        this.setState({ 
            showModal: !this.state.showModal,
            savedPhoto: null,
        });
    }

    onChangeProducto = (event) => {
        this.setState({producto:event.target.value});
    }

    onChangeDescripcion = (event) => {
        this.setState({descripcion: event.target.value});
    }
    
    onChangePresentacion = (value) => {
        this.setState({presentacion: value});
    }

    onChangeFoto = (event) => {
        //console.log("image== ",event.target.files)
        this.setState({foto: event.target.files});
    };

    editInfo = () => {
        const { loading,showModal,newItem,producto,presentacion,descripcion,savedPhoto } = this.state;
        return(
            <Modal
                visible={showModal}
                maskClosable={true}
                footer={null}
                onCancel={this.toggleModal}
                style={{top:125}}
            >
            <div style={{padding:20, backgroundColor:'#fff', textAlign:'center'}}>
                <div>
                    { newItem ? <h1 style={{fontSize:'1.5em'}}>Nuevo Registro</h1> : <h1 style={{fontSize:'1.5em'}}>Editando Registro</h1> }
                </div>
                <Form
                    //{...layout}
                    name="registroLicorera"
                    //onFinish={onFinish}
                    //onFinishFailed={onFinishFailed}
                >
                <div className="frmEditM">
                    <Input placeholder="Nombre del producto" id="producto" prefix={<TagOutlined style={{fontSize:21}} />} onChange={this.onChangeProducto} value={producto} />
                
                    <TextArea
                        rows={3}
                        maxLength={148}
                        placeholder="Descripción corta del producto" id="descripcion"
                        prefix={<TagOutlined style={{fontSize:18}} />}
                        onChange={this.onChangeDescripcion} value={descripcion}
                        style={{marginLeft:5}}
                    />

                    <Select
                        mode="multiple"
                        placeholder="Elija la presentación"
                        onChange={this.onChangePresentacion} value={presentacion}
                        style={{width:'100%', marginLeft:5, marginTop:5}}
                        
                    >
                        {this.state.presentaciones}
                    </Select>

                    <div style={{maxWidth:'100%', maxHeight:200, overflow:'hidden',margin:7}}>
                        {savedPhoto !== null ? <img src={savedPhoto} alt={producto} style={{maxWidth:'100%',maxHeight:200}} /> : <h3 style={{padding:10, color:'#afafaf'}}>Sin imagen de referencia</h3>}
                    </div>

                    <input type="file" id="fileinput" accept="image/*" style={{marginLeft:5,marginTop:5}} onChange={this.onChangeFoto} />

                    { newItem
                        ?
                            <Button type="primary" htmlType="submit" loading={loading} onClick={this.btnGuardar} style={{marginTop:5}}>
                                Guardar
                            </Button>
                        :
                            <Button type="primary" htmlType="submit" loading={loading} onClick={this.btnUpdate} style={{marginTop:5}}>
                                Actualizar
                            </Button>
                    }
                </div>
                </Form>
            </div>
            </Modal>
        )
    };

    btnNuevo = () => {
        this.setState({ newItem: true, producto:'', descripcion:'', presentacion:[] });
        this.toggleModal();
    }

    btnGuardar = async () => {
        let key = 'updatable';
        const { producto, descripcion, presentacion, foto } = this.state;

        this.setState({ loading:true });

        message.loading({ content: 'Guardando...', key });
        saveProducto( producto, descripcion, presentacion )
        .then(res => {
            //si se cargó foto
            if(foto.length > 0){
                //se actualiza la fotografía
                updatePhotoProducto(res.id, foto)
                .then(result =>{
                    this.setState({loading:false, errorSaving:false, foto:[]});
                    //actualiza data
                    this.toggleModal();
                    this.dataApi();
                    message.success({ content: 'Regsitro Guardado', key, duration: 3 });
                })
                .catch(error => {
                    this.setState({loading:false, errorSaving:true});
                    this.toggleModal();
                    alert(error.message);
                })
            }else{
                this.setState({loading:false, errorSaving:false});
                this.toggleModal();
                //actualiza data
                this.dataApi();
                message.success({ content: 'Regsitro Guardado', key, duration: 3 });
            }
        })
        .catch(err => {
            this.setState({loading:false, showModal:false});
            alert(err.message);
        })
    }

    btnEdit = (record) => {
        //console.log("edit== ",record)
        this.setState({
            newItem: false,
            idReg: record.id,
            producto: record.producto,
            descripcion: record.descripcion,
            presentacion: record.presentaciones,
        });
        this.toggleModal();

        if( record.imagen !== null && record.imagen !== undefined && record.imagen !== '' ){
            if( record.imagen.length >= 1 ){
                this.setState({
                    savedPhoto: _SERVER_ + record.imagen[0].url
                });
            }else{
                this.setState({ savedPhoto: null });
            }
        }else{
            this.setState({ savedPhoto: null });
        }
    }

    btnUpdate = () => {
        let key = 'updatable';
        const { idReg, producto, descripcion, presentacion, foto } = this.state;
        this.setState({loading:true});

        //console.log(producto," ",descripcion)
        message.loading({ content: 'Actualizando...', key });
        updateProducto( idReg, producto, descripcion, presentacion )
        .then(res => {
            //si se cargó foto
            if(foto.length > 0){
                //se actualiza la fotografía
                updatePhotoProducto(res.id, foto)
                .then(result =>{
                    this.setState({loading:false, errorSaving:false, foto:[]});
                    //actualiza data
                    this.toggleModal();
                    this.dataApi();
                    message.success({ content: 'Regsitro Actualizado', key, duration: 3 });
                })
                .catch(error => {
                    this.setState({loading:false, errorSaving:true});
                    this.toggleModal();
                    alert(error.message);
                })
            }else{
                this.setState({loading:false, errorSaving:false});
                this.toggleModal();
                //actualiza data
                this.dataApi();
                message.success({ content: 'Regsitro Actualizado', key, duration: 3 });
            }
            //se resetea el id del registro editado
            this.setState({ idReg:null });
        })
        .catch(err => {
            //se resetea el id del registro editado
            this.setState({ idReg:null });
            alert(err.message);
        })
    }

    btnDelete = idKey => {
        let key = 'updatable';
        const dataSource = [...this.state.points];
        let delReg = dataSource.filter(item => item.id === idKey);
    
        message.loading({ content: 'Eliminando...', key });
        deleteProducto(delReg[0].id)
        .then(res => {
          this.setState({
            dataSource: dataSource.filter(item => item.id !== idKey),
          });
          this.dataApi();
          message.success({ content: 'Regsitro eliminado', key, duration:3 });
        })
        .catch(err => {
          alert(err.message);
        })
    };

    render(){
        const columns = [
            {
                title: 'Nombre Producto',
                dataIndex: 'producto',
                key: 'producto',
                //render: text => <a>{text}</a>,
            },
            {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
            },
            {
                title: 'Acciones',
                render: (text, record) => (
                    <>
                        <Space size="middle">
                            <a onClick={() => this.btnEdit(record)}>Editar </a>

                            { this.state.points.length >= 1 ? (
                                <Popconfirm title="¿Eliminar definitivamente?" onConfirm={() => this.btnDelete(record.id)}>
                                    <a>Eliminar</a>
                                </Popconfirm>
                            ) : null }
                        </Space>
                    </>
                ),
            },
        ];

        return(
            <>
                <div style={{width:'100%', textAlign:'left',marginTop:18}}>
                    <Button
                        onClick={this.btnNuevo}
                        type="primary"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        Nuevo producto
                    </Button>
                </div>

                <Table rowKey="id" loading={this.state.loadingTable} columns={columns} dataSource={this.state.points} />
                
                {/* modal con información a editar */}
                { this.editInfo() }
            </>
        )
    };
}